.result-table div {
  width: 100%;
  align-items: center;
  justify-content: center;
}

/* Subtitle styling */
.result-table-subtitle {
  font-size: 16px;
  font-style: italic;
  color: #666;
  margin-top: 4px;
  text-align: center;
}

/* Title container */
.result-table-title-container {
  margin-bottom: 20px;
  text-align: center;
}

/* Animate row transitions for slideshow effect */
.carousel-slide-entering .MuiDataGrid-row {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.carousel-slide-entered .MuiDataGrid-row {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Better mobile support */
@media (max-width: 600px) {
  .result-table h1 {
    font-size: 22px;
    margin-bottom: 6px;
  }
  
  .result-table-subtitle {
    font-size: 14px;
  }
  
  .result-table .MuiDataGrid-columnHeaders {
    min-height: 48px !important;
  }
  
  .result-table .MuiDataGrid-cell {
    font-size: 14px !important;
    padding: 8px 4px !important;
  }
}

/* Essential fix for horizontal scrolling */
.result-table {
  width: 100%;
  overflow-x: auto !important; /* Enable container scrolling */
}

/* Ensure the table can be wider than its container */
.MuiDataGrid-root {
  min-width: 600px !important; /* Set a minimum width to ensure scrolling */
  overflow-x: auto !important;
}

/* Fix headers not centering */
.MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center !important;
}

/* Keep original styles below */
.result-table div {
  width: 100%;
  align-items: center;
  justify-content: center;
}

/* Better mobile support */
@media (max-width: 600px) {
  .result-table h1 {
    font-size: 22px;
    margin-bottom: 6px;
  }
  
  .result-table-subtitle {
    font-size: 14px;
  }
  
  .result-table .MuiDataGrid-columnHeaders {
    min-height: 48px !important;
  }
  
  .result-table .MuiDataGrid-cell {
    font-size: 14px !important;
    padding: 8px 4px !important;
  }
}


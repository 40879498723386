/* Landing Page (main dashboard) */
.section-landing-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100%;
  background-color: #6B6E70;
  padding: 0; /* Remove padding to ensure full height */
}

.section-landing-page main {
  flex: 1; /* Take up remaining space */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-landing-page h1 {
  width: 85%;
  font-family: "Montserrat", Arial, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #ffffff;
  line-height: 48px;
}

/* Banner */
.section-banner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100%-90px);
  justify-content: center;
  align-items: center;
  /* background-color: #0281C5; */
  background-color: #6B6E70;    
	padding-top: 75px;
	padding-bottom: 75px;
}

.section-banner h1 {
  width: 85%;
  font-family: "Montserrat", Arial, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #ffffff;
  line-height: 48px;
}

/* Pots */
.pot-div {
  display: flex;
  justify-content: center; /* Align items horizontally */
  align-items: center; /* Align items vertically */
  width: 100%; /* Ensure the div takes full width */
  height: 100%; /* Ensure full height for vertical centering */
}
.pot-autocomplete {
  width: 400px; /* Set a fixed width to the Autocomplete */
  display: flex;
  justify-content: center; /* Center within its parent */
  align-items: center; /* Center the content vertically */
}
/* Apply direction: rtl for the scroll container */
.scroll-wrapper {
  overflow-x: auto;
  direction: rtl; /* Reverse scroll direction */
}

.scroll-content {
  display: inline-block;
  direction: ltr; /* Reset the table content direction */
}

/* Freeze first column (Teams) */
.sticky-col {
  position: sticky;
  left: 0;
  background-color: #fff; /* Ensure the first column remains with a background */
  z-index: 1;
  font-weight: bold;
}

/* Table styling adjustments for responsiveness */
.pot-table {
  font-size: 14px;
  width: 100%;
  border-collapse: collapse;
}

.pot-table th,
.pot-table td {
  padding: 4px;
  border: 1px solid #ddd;
  text-align: center;
}

.pot-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 2;
}

@media (max-width: 768px) {
  .pot-table th,
  .pot-table td {
    font-size: 12px;
    padding: 3px;
  }
}

@media (max-width: 480px) {
  .pot-table th,
  .pot-table td {
    font-size: 10px;
    padding: 2px;
  }
}


/* Signup */
.section-signup {
  width: 100%;
}

/* Leaderboard */
.section-leaderboard {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  /* padding-top: 20px; */
  /* padding-bottom: 10px; */
}
.section-leaderboard div {
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  /* padding-bottom: 5px; */
  background-color: #ffffff;
}
.section-leaderboard h1 {
  width: 85%;
  font-family: "Open Sans", Arial, serif;
  font-style: normal;
  font-weight: 550;
  font-size: 26px;
  color: #333333;
  line-height: 31px;
  padding-bottom: 50px;
}
.leaderboard-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.leaderboard-row {
  display: flex;
  flex-direction: row;
  width: 75%;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
}
.leaderboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%;
  padding: 0px;
}
.leaderboard-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #0281C5;
  padding-bottom: 15px;
}
.leaderboard-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.leaderboard-text h4 {
  font-family: "Open Sans", Arial, serif;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
  line-height: 19px;
  text-align: center;
  padding-bottom: 5px;
}
.leaderboard-text p {
  font-family: "Open Sans", Arial, serif;
  font-weight: 700;
  font-size: 16px;
  color: #0281C5;
  line-height: 25px;
  text-align: left;
  padding-bottom: 5px;
}
.timestamp-text {
  color: var(--timestamp-text-color);
}


/* DYNAMIC RESIZING */
@media (max-width: 950px) {
  .leaderboard-row {
    display: flex;
    flex-direction: column;
    width: 95%;
    align-items: center;
    justify-content: space-between;
    padding-top: 0px;
  }
  .leaderboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 30px;
  }
  .section-leaderboards h1 {
    width: 100%;
    font-family: "Open Sans", Arial, serif;
    font-weight: 600;
    font-size: 26px;
    color: #222222;
    line-height: 31px;
    padding-bottom: 10px;
  }
  .section-leaderboards p {
    width: 100%;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #777777;
    line-height: 25px;
  }
} 

/* Contact */
.section-contact {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding-top: 50px;
  /* padding-bottom: 25px; */
}
.section-contact h1 {
  width: 85%;
  font-family: "Open Sans", Arial, serif;
  font-style: normal;
  font-weight: 550;
  font-size: 26px;
  color: #333333;
  line-height: 31px;
  padding-bottom: 5px;
}
.contact-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.contact-row {
  display: flex;
  flex-direction: row;
  width: 75%;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
}
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%;
  padding: 0px;
}
.contact-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #0281C5;
  padding-bottom: 15px;
}
.contact-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.contact-text h4 {
  font-family: "Open Sans", Arial, serif;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
  line-height: 19px;
  text-align: center;
  padding-bottom: 5px;
}
.contact-text p {
  font-family: "Open Sans", Arial, serif;
  font-weight: 700;
  font-size: 16px;
  color: #0281C5;
  line-height: 25px;
  text-align: left;
  padding-bottom: 5px;
}


/* DYNAMIC RESIZING */
@media (max-width: 950px) {
  .contact-row {
    display: flex;
    flex-direction: column;
    width: 95%;
    align-items: center;
    justify-content: space-between;
    padding-top: 0px;
  }
  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 30px;
  }
  .section-contacts h1 {
    width: 100%;
    font-family: "Open Sans", Arial, serif;
    font-weight: 600;
    font-size: 26px;
    color: #222222;
    line-height: 31px;
    padding-bottom: 10px;
  }
  .section-contacts p {
    width: 100%;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #777777;
    line-height: 25px;
  }
} 


/* Placeholder */
.placeholder-div {
  width: 100%;
}

.placeholder-div h1 {
  width: 100%;
}

/* View */
.section-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding-top: 0px;
  padding-bottom: 75px;
}

.select-div {
  /* padding-top: 10px; */
  padding-bottom: 30px;
}
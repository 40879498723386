/* Teams Hero Section Styles */
.teams-hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  border-bottom: 1px solid #e5e7eb;
  background-color: #ffffff;
  color: #0d1b2a;
  overflow: hidden;
}

.teams-hero-section .container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1280px;
  width: 100%;
  position: relative;
  gap: 2rem;
}

.teams-text {
  flex: 1;
  max-width: 50%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  word-wrap: break-word; /* Ensure long words wrap properly */
}

.subheadline {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.teams-chiron {
  flex: 1;
  max-width: 50%;
  overflow: hidden;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f5f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.chiron-wrapper {
  display: flex;
  flex-direction: column;
  animation: scroll-roller 10s linear infinite;
  text-align: center;
}

.chiron-item {
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1rem 0;
}

/* Vertical Scroll Animation */
@keyframes scroll-roller {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

/* Mobile Stacking */
@media (max-width: 768px) {
  .teams-hero-section .container {
    flex-direction: column;
  }

  .teams-text {
    max-width: 100%;
    width: 100%;
    text-align: center;
    padding: 2rem 1rem; /* Ensure sufficient spacing */
  }

  .teams-chiron {
    max-width: 100%;
    width: 100%;
    margin-top: 1rem;
  }

  .title {
    font-size: 2rem; /* Adjust font size for mobile */
    margin-bottom: 0.5rem;
  }

  .subheadline {
    font-size: 1rem; /* Adjust font size for mobile */
  }
}

/* Countdown timer styling for blue background */
.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0.25rem;
  margin: 0.5rem;
  background: rgba(0, 0, 0, 0.2); /* Subtle background to contrast */
}

.expired-notice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ffffff; /* White text for readability */
}

.expired-notice > p {
  font-size: 1.5rem;
  color: #ffffff;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.75rem;
  padding: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0.25rem;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.2); /* Subtle background for contrast */
  color: #ffffff; /* White font color */
}

.show-counter .countdown {
  line-height: 2.25rem;
  padding: 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ffffff; /* Keep the font white for danger state */
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgba(255, 255, 255, 0.8); /* Slightly dimmed white for labels */
}

/* General styling adjustments for better readability on blue */
.pageContainer {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.buttonContainer {
  display: inline-flex;
  flex-flow: row;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: transparent;
  color: #ffffff; /* White font for buttons */
  z-index: 1001;
}

.paymentContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 250px;
  color: #ffffff;
}

/* EventSupportPage.css */

/* Banner styles */
.section-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.section-banner h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 2;
  letter-spacing: 1px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .section-banner {
    height: 120px;
  }
  
  .section-banner h1 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .section-banner {
    height: 100px;
  }
  
  .section-banner h1 {
    font-size: 1.75rem;
  }
}

/* Prevent content from flowing under the footer */
main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Add some spacing to the contact form container */
.support-container {
  flex: 1;
  padding: 30px 20px;
}


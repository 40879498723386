.nav-container {
  position: fixed;
  width: 100%;
  z-index: 50;
  transition: all 0.3s ease;
}

.nav-scrolled {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-transparent {
  background-color: transparent !important;
}

.nav-content {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Logo */
.nav-logo {
  height: 50px;
  padding: 8px;
  border-radius: 4px;
  background-color: white;
}

.nav-logo img {
  height: 100%;
  width: auto;
}

/* Desktop Menu */
.nav-menu {
  display: none;
}

@media (min-width: 768px) {
  .mobile-menu-button {
    display: none;
  }
  .nav-menu {
    display: flex;
    gap: 2rem;
    align-items: center;
  }
}

.nav-link {
  color: black;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.2s ease;
}

.nav-transparent .nav-link {
  color: white;
}

.nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.nav-transparent .nav-link:hover {
  color: rgba(255, 255, 255, 0.8);
}

/* Dropdown */
.nav-dropdown {
  position: relative;
}

.dropdown-button {
  color: black;
  font-size: 1rem;
  font-weight: 500;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.nav-transparent .dropdown-button {
  color: white;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  padding: 0; /* Remove unnecessary padding from the container */
  margin-top: 0;
  z-index: 1000;
}

.nav-dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-item {
  display: block;
  width: 100%; /* Ensure the button spans the full width of the dropdown */
  padding: 0.75rem 1rem; /* Define consistent padding */
  margin: 0; /* Reset margin */
  color: #374151;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  text-align: left; /* Align text to the left */
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-radius: 0; /* Remove rounded edges */
  box-sizing: border-box; /* Ensure padding is included in width/height calculations */
}

.dropdown-item:hover {
  background-color: #f3f4f6; /* Highlight the entire width on hover */
}

/* Reset all button styles in the dropdown context */
.nav-dropdown .dropdown-item {
  all: unset; /* Reset inherited styles */
  display: block;
  width: 100%;
  padding: 0.75rem 1rem; /* Reapply required styles after resetting */
  margin: 0; /* Reset any margin */
  color: #374151;
  text-align: left;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  background: none;
  transition: background-color 0.2s ease;
  box-sizing: border-box;
}

.nav-dropdown .dropdown-item:hover {
  background-color: #f3f4f6;
}

/* Mobile menu */
.mobile-menu {
  display: none; /* Default hidden */
  position: fixed;
  top: 80px; /* Below the navbar */
  right: 0;
  width: 100%;
  height: calc(100vh - 80px); /* Fullscreen height minus the navbar */
  background: white;
  padding: 1rem;
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 1000; /* Ensure visibility over other elements */
}

.mobile-menu.active {
  display: block; /* Show menu when active */
}

.mobile-menu .mobile-menu-section {
  margin-bottom: 1rem; /* Add spacing between sections */
  border-bottom: 1px solid #e5e7eb; /* Subtle divider */
}

/* Mobile menu */
.mobile-menu .mobile-menu-link {
  display: block;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #374151;
  text-decoration: none;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 100%;
  box-sizing: border-box;
}

.mobile-menu .mobile-menu-link:hover {
  background-color: #f3f4f6;
}

.mobile-menu .mobile-menu-dropdown {
  margin-bottom: 1rem; /* Spacing between dropdown sections */
}

.mobile-menu .dropdown-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
}

.mobile-menu .dropdown-content {
  display: none;
  background: white;
  padding: 0.5rem 0;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-left: 1rem; /* Indent dropdown items */
}

.mobile-menu .dropdown-button:hover + .dropdown-content,
.mobile-menu .dropdown-content:hover {
  display: block; /* Show dropdown on hover */
}

.mobile-menu .dropdown-item {
  display: block;
  padding: 0.5rem 1rem; /* Adjust padding for dropdown items */
  font-size: 0.9rem; /* Slightly smaller font size */
  color: #6b7280; /* Subtle color difference */
  cursor: pointer;
}

.mobile-menu .dropdown-item:hover {
  color: #374151; /* Highlight color */
  background-color: #f3f4f6;
}

.mobile-menu-button {
  display: block; /* Default: Visible on mobile */
  padding: 0.5rem;
  background: none;
  border: none;
  box-shadow: none;
  color: black;
  cursor: pointer;
  outline: none;
  transition: color 0.2s ease;
}

.nav-transparent .mobile-menu-button {
  color: white;
}

/* Modal */
.absolute {
  position: absolute;
}

.right-2 {
  right: 0.5rem;
}

.top-2 {
  top: 0.5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.w-full {
  width: 100%;
}

.h-auto {
  height: auto;
}

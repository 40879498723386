/* Sponsors Hero Section Styles */
.sponsors-hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  border-bottom: 1px solid #e5e7eb;
  background-color: #ffffff;
  color: #0d1b2a;
  overflow: hidden;
  width: 100%;
}

.sponsors-hero-section .container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1280px;
  width: 100%;
  position: relative;
  gap: 2rem;
}

.sponsors-text {
  flex: 1;
  min-width: 300px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #0281C5;
}

.subheadline {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.sponsors-marquee {
  flex: 1;
  min-width: 300px;
  overflow: hidden;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8fafc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.marquee-wrapper {
  display: flex;
  flex-direction: column;
  animation: scroll-sponsors 30s linear infinite;
  text-align: center;
  width: 100%;
  padding: 0 2rem;
}

.sponsor-item {
  padding: 1rem 0;
  margin: 0.5rem 0;
  transition: all 0.3s ease;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Different styles based on sponsor tier weight */
.tier-weight-1 {
  font-size: 1.75rem;
  font-weight: 700;
  padding: 1.25rem 0;
  border: 2px solid #E5E4E2; /* Platinum-like border */
}

.tier-weight-2 {
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1.1rem 0;
  border: 2px solid #FFD700; /* Gold-like border */
}

.tier-weight-3 {
  font-size: 1.25rem;
  font-weight: 600;
  border: 2px solid #C0C0C0; /* Silver-like border */
}

.tier-weight-4 {
  font-size: 1.1rem;
  font-weight: 500;
  border: 2px solid #CD7F32; /* Bronze-like border */
}

.tier-weight-5, 
.tier-weight-999 {
  font-size: 1rem;
  font-weight: 500;
  border: 1px solid #e2e8f0;
}

.sponsor-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 80px;
  padding: 0 1rem;
}

.sponsor-logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.sponsor-name-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
}

.sponsor-name {
  text-align: center;
  margin-bottom: 0.25rem;
  font-weight: 600;
}

.sponsor-tier {
  font-size: 0.75em;
  opacity: 0.7;
  color: #0281C5;
}

/* Vertical Scroll Animation with pause on hover */
@keyframes scroll-sponsors {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.marquee-wrapper:hover {
  animation-play-state: paused;
}

.sponsors-hero-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: #f9fafb;
  font-size: 1.1rem;
  color: #6b7280;
}

/* Mobile Stacking */
@media (max-width: 768px) {
  .sponsors-hero-section .container {
    flex-direction: column;
  }

  .sponsors-text {
    max-width: 100%;
    width: 100%;
    text-align: center;
    padding: 2rem 1rem; /* Ensure sufficient spacing */
  }

  .sponsors-marquee {
    max-width: 100%;
    width: 100%;
    margin-top: 1rem; /* Ensure space between text and marquee */
    height: 250px; /* Slightly shorter on mobile */
  }

  .title {
    font-size: 2rem; /* Adjust font size for mobile */
    margin-bottom: 0.5rem;
  }

  .subheadline {
    font-size: 1rem; /* Adjust font size for mobile */
  }

  /* Adjust tier weights for mobile */
  .tier-weight-1 {
    font-size: 1.5rem;
    padding: 1rem 0;
  }

  .tier-weight-2 {
    font-size: 1.3rem;
    padding: 0.9rem 0;
  }
}


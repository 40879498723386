/* DashboardPage.css */
/* Center the headings */
.dashboard-content h2 {
  text-align: center;
  margin: 20px 0; /* Optional spacing */
  font-size: 1.5rem;
}

/* General layout styles for Dashboard */
.dashboard-main-container {
  display: flex;
  height: 100vh; /* Full viewport height */
  width: 100%;
  background-color: #f4f4f4; /* Light background for main content */
}

/* Background style for login page */
.login-background {
  background-color: #6B6E70; /* Revert to original grey background for login */
}

/* Sidebar styling */
.dashboard-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.3s ease;
  background-color: #6B6E70; /* Sidebar background */
  z-index: 2;
}

/* Sidebar Header */
.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #fff;
}

.sidebar-title {
  color: white;
  margin: 0;
  font-size: 1.2em;
}

.sidebar-content {
  padding: 20px;
  color: white;
  text-align: center;
}

.sidebar-content p {
  margin: 10px 0;
}

.sidebar-content .MuiButton-root {
  margin-top: 20px;
}

/* Main content area */
.dashboard-main-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
}

/* Ensure the content box centers correctly */
.dashboard-main-content > .MuiBox-root {
  width: 100%; /* Full width of container */
  max-width: 1200px; /* Optional: Set a max width */
}

/* Adjust main content margin when sidebar is collapsed */
.sidebar-collapsed .dashboard-main-content {
  margin-left: 70px; /* Collapsed sidebar width */
}

/* Tournament Cards Container */
.dashboard-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px; /* Add spacing between cards */
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Styling for .section-landing-page */
.section-landing-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #6B6E70; /* Original grey background */
}

.section-landing-page main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-landing-page h1 {
  width: 85%;
  font-family: "Montserrat", Arial, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #ffffff;
  line-height: 48px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .dashboard-sidebar {
    width: 70px;
  }

}


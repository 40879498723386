/* Sponsor Page Styles */
.section-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* padding: 125px 125px; */
  padding-top: 125px;
  padding-bottom: 100px;
  text-align: center;
}

.section-banner h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
}

.section-sponsors {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 50px 20px;
}

.section-title {
  color: #212529;
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}

.sponsor-tier {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 40px;
  padding: 0 20px;
}

.tier-title {
  color: #333;
  font-size: 1.75rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
  position: relative;
}

.tier-title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
}

/* Tier specific colors */
.sponsor-platinum .tier-title::after {
  background-color: #E5E4E2; /* Platinum color */
}

.sponsor-gold .tier-title::after {
  background-color: #FFD700; /* Gold color */
}

.sponsor-silver .tier-title::after {
  background-color: #C0C0C0; /* Silver color */
}

.sponsor-bronze .tier-title::after {
  background-color: #CD7F32; /* Bronze color */
}

.sponsor-custom .tier-title::after,
.sponsor-other .tier-title::after {
  background-color: #0281C5; /* Default color */
}

.sponsors-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  width: 100%;
}

/* Adjust grid sizes based on tier importance */
.sponsor-platinum .sponsors-grid {
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}

.sponsor-gold .sponsors-grid {
  grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
}

.sponsor-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #eaeaea;
}

.sponsor-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.sponsor-logo-container {
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  padding: 20px;
  border-bottom: 1px solid #eaeaea;
}

.sponsor-logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.sponsor-no-logo {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  padding: 15px;
  border-bottom: 1px solid #eaeaea;
}

.sponsor-no-logo h3 {
  color: #666;
  font-size: 1.5rem;
  margin: 0;
  text-align: center;
}

.sponsor-details {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.sponsor-details h3 {
  color: #333;
  font-size: 1.25rem;
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: 600;
}

.sponsor-website {
  display: inline-block;
  color: #0281C5;
  text-decoration: none;
  font-weight: 500;
  margin-bottom: 15px;
  transition: color 0.2s ease;
}

.sponsor-website:hover {
  color: #026999;
  text-decoration: underline;
}

.sponsor-description {
  color: #555;
  font-size: 0.95rem;
  line-height: 1.5;
  margin-bottom: 0;
}

.no-sponsors {
  text-align: center;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 20px 0;
  width: 80%;
  max-width: 800px;
  border: 1px solid #eaeaea;
}

.no-sponsors p {
  font-size: 1.1rem;
  color: #555;
  margin: 0;
}

/* Become a Sponsor Section */
.section-become-sponsor {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  padding: 60px 20px;
  text-align: center;
  border-top: 1px solid #eaeaea;
}

.section-become-sponsor h1 {
  color: #212529;
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.sponsor-info {
  max-width: 800px;
  margin-bottom: 30px;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #444;
}

.sponsor-tiers-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
  width: 100%;
  max-width: 1200px;
}

.sponsor-tier-info {
  flex: 1;
  min-width: 250px;
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
  border: 1px solid #eaeaea;
  transition: transform 0.3s ease;
}

.sponsor-tier-info:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.sponsor-tier-info h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #333;
  font-weight: 600;
}

.sponsor-tier-info p {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
  margin-bottom: 0;
}

/* Custom styling for custom tier */
.custom-tier h2 {
  color: #0281C5;
}

.sponsor-button {
  display: inline-block;
  /* padding: 15px 30px; */
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  background-color: #0281C5;
  color: white;
  border: 2px solid black;  /* Black border to match register page */
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 250px;  /* Ensure minimum width */
  margin: 1rem auto;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  width: 300px;
  max-width: 100%;
}

.sponsor-button:hover {
  background-color: #026999;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

/* Match the home page button styling too */

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .section-banner h1,
  .section-sponsors h1,
  .section-become-sponsor h1 {
    font-size: 1.8rem;
  }
  
  .tier-title {
    font-size: 1.5rem;
  }
  
  .sponsors-grid {
    grid-template-columns: 1fr !important;
  }
  
  .sponsor-tiers-info {
    flex-direction: column;
    align-items: center;
  }
  
  .sponsor-tier-info {
    width: 100%;
    max-width: 400px;
  }

  .sponsor-details h3 {
    font-size: 1.2rem;
  }

  .sponsor-button {
    width: 100%;
    max-width: 300px;
  }
}
/* Base styles */
.min-h-screen {
  min-height: 100vh;
}

/* Hero section */
.relative {
  position: relative;
}

.h-screen {
  height: 100vh;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-blue-600 {
  --tw-gradient-from: #2563eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(37 99 235 / 0));
}

.to-blue-800 {
  --tw-gradient-to: #1e40af;
}

.absolute {
  position: absolute;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bg-black {
  background-color: #000;
}

.opacity-50 {
  opacity: 0.5;
}

.z-10 {
  z-index: 10;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #fff;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.font-bold {
  font-weight: 700;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

/* Stats section */
.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.bg-white {
  background-color: #fff;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.grid {
  display: grid;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.gap-8 {
  gap: 2rem;
}

.transform {
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.p-8 {
  padding: 2rem;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-shadow);
}

.mb-2 {
  margin-bottom: 0.5rem;
}

/* Responsive utilities */
@media (min-width: 768px) {
  .md\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 1s ease-out forwards;
}

/* Hover effects */
.transform:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

/* Teams and Catches Section */
.stats-section {
  background-color: #f3f4f6;
  padding: 5rem 0;
}

.stats-section .bg-blue-600,
.stats-section .bg-blue-700 {
  border-radius: 1rem;
  transition: transform 0.3s ease;
}

.stats-section .bg-blue-600:hover,
.stats-section .bg-blue-700:hover {
  transform: scale(1.05);
}

/* Services Section */
.service-card {
  text-align: center;
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.service-card .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  margin-bottom: 1rem;
}

/* Event Buttons */
.event-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.button-section {
  margin-bottom: 2rem;
  text-align: center;
  width: 100%;
}

.button-section:not(:first-child) {
  margin-top: 1rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

/* Button styling */
.home-signup-button {
  text-align: center;
  display: inline-block;
  margin: 1rem auto;
  width: 300px;
  max-width: 100%;
}

/* Mobile Responsiveness Additions */
@media (max-width: 768px) {
  .mobile-hero-section {
    height: auto;
    min-height: 100vh;
    padding: 100px 0 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-hero-content {
    width: 95%;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 10px;
  }

  .mobile-title {
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }

  .mobile-subtitle {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }

  .mobile-details-container {
    padding: 1.5rem;
    margin-top: 1rem;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }

  .mobile-hero-section * {
    box-sizing: border-box;
  }

  /* Mobile button styling */
  .event-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .button-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }
  
  .home-signup-button {
    display: block;
    margin: 1rem auto;
    width: 80%;
    max-width: 300px;
  }
  
  /* Fix the text alignment in the button sections */
  .button-section h2,
  .button-section p {
    width: 100%;
    text-align: center;
  }

  /* Ensure the link containing the button is centered */
  .mobile-hero-content .mt-8 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Adjust text sizes for mobile */
  .mobile-hero-content h1,
  .mobile-hero-content h2,
  .mobile-hero-content p {
    text-align: center;
  }
}

/* Fix for tablet and desktop */
@media (min-width: 769px) {
  .mobile-hero-section {
    height: auto;
    min-height: 100vh;
    padding: 120px 0 60px 0;
  }
  
  .mobile-details-container {
    max-height: none;
    overflow: visible;
    padding: 2rem;
  }
  
  .home-signup-button {
    display: inline-block;
    margin: 0.5rem;
  }
}

